import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";

Vue.use(VueMeta);

Vue.use(VueGtag, {
  config: { id: "G-G1W8JY2H50" }, // Measurement ID maybe i need Tracking ID
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
