<template>
  <div class="comicStrip_wrapper">
    <img src="@/assets/images/comic/pane1.png" class="comicImg1 comicImg" />
    <img src="@/assets/images/comic/pane2.png" class="comicImg2 comicImg" />
    <img src="@/assets/images/comic/pane3.png" class="comicImg3 comicImg" />
    <img src="@/assets/images/comic/pane4.png" class="comicImg4 comicImg" />
  </div>
</template>

<script>
export default {
  name: "ComisStrip",
};
</script>

<style lang='scss' src='../assets/scss/comic-strip.scss'></style>