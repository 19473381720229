import Vue from 'vue'
import VueRouter from 'vue-router'
import PuzzleEntry from '../views/PuzzleEntry.vue'
import PuzzlePrize from '../views/PuzzlePrize.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeScreen',
    component: PuzzleEntry
  },
  {
    path: '/prize',
    name: 'PrizeScreen',
    component: PuzzlePrize
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
