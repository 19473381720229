<template>
  <hooper v-bind="settings">
    <slide>
      <img src="../assets/images/comic/pane1.png" alt="" />
    </slide>
    <slide>
      <img src="../assets/images/comic/pane2.png" alt="" />
    </slide>
    <slide>
      <img src="../assets/images/comic/pane3.png" alt="" />
    </slide>
    <slide>
      <img src="../assets/images/comic/pane4.png" alt="" />
    </slide>

    <hooper-pagination slot="hooper-addons"></hooper-pagination>
  </hooper>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "ComicSlide",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      settings: {
        centerMode: true,
        autoPlay: true,
        playSpeed: 15000,
        wheelControl: false,
        transition: 500,
      },
    };
  },
};
</script>

<style lang="scss" src='../assets/scss/comic-slide.scss'></style>