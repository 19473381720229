<template>
  <footer
    :class="[!prizeRoute ? 'entryDelay_animation' : 'prizeDelay_animation']"
  >
    <a
      href="https://www.searchforsnoopy.com/privacy-policy"
      target="_blank"
      class="copyright"
    >
      Privacy Policy
    </a>

    <figure></figure>

    <p class="copyright">PNTS©</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    prizeRoute() {
      let routeName = true;
      this.$route.name === "HomeScreen" ? (routeName = false) : routeName;
      return routeName;
    },
  },
};
</script>

<style lang='scss'>
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .copyright {
    font-family: "Typewriter";
    font-size: 22px;
    font-weight: 600;
    color: white;
    text-decoration: underline;
  }

  figure {
    width: 6px;
    height: 6px;
    background: white;
    border-radius: 100%;
  }
}

.entryDelay_animation {
  -webkit-animation: slide-in-bottom 0.5s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s
    both;
}

.prizeDelay_animation {
  -webkit-animation: slide-in-bottom 0.5s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s
    both;
}

//--- Tablet
@media (min-width: 738px) and (max-width: 900px) {
  footer {
    .copyright {
      font-size: 18px;
    }
  }
  .prizeDelay_animation {
    animation-delay: 2.3s;
    -webkit-animation-delay: 2.3s;
  }
}
//--- Mobile
@media (max-width: 737px) {
  footer {
    .copyright {
      font-size: 16px;
    }
  }
  .prizeDelay_animation {
    animation-delay: 2.3s;
    -webkit-animation-delay: 2.3s;
  }
}
</style>