<template>
  <main class="entryInput_container">

    <h1 :class="[handlerAnimation ? 'initial_text--out' : 'initial_text']">
      So... What should Charlie Brown<br />
      shout out to bring Snoopy home?
    </h1>

    <span
      :class="[handlerAnimation ? 'errorAnswer_text--in' : 'errorAnswer_text']"
    >
      <h1 :class="[errorAnimation ? 'errorAnimation' : 'errorAnimation-none']">
        Oops! Snoopy is not coming... <br />
        Give another try!
      </h1>
    </span>

    <input
      @keypress.enter="checkSecretWord"
      v-model="secretWord"
      ref="wordInput"
      type="text"
      class="entry_input"
      maxlength="12"
      placeholder="secret phrase"
    />

    <button
      :disabled="disabled"
      @click="checkSecretWord"
      :class="[
        secretWord.length === 0 ? 'continue_btn--disabled' : 'continue_btn',
      ]"
    >
      <h2>SHOUT IT!</h2>
    </button>
  </main>
</template>

<script>
export default {
  name: "EntryInput",
  data() {
    return {
      secretWord: "",
      disabled: false,
      incorrectWord: true,
      handlerAnimation: false,
      errorAnimation: false,
    };
  },
  methods: {
    checkSecretWord() {
      let word = this.secretWord.toUpperCase();

      if (word === "SUPPER TIME") {
        this.goToPrize();
      } else {
        
        if (this.incorrectWord === false) {
          this.errorAnimation = true;
          setTimeout(() => {
            this.errorAnimation = false;
          }, 1000);
        }

        this.handlerAnimation = true;

        this.incorrectWord = false;

        this.$refs.wordInput.focus();
      }
    },

    goToPrize() {
      this.$router.push({ path: "/prize" });
    },
  },
  mounted() {},
};
</script>

<style lang='scss' src='@/assets/scss/entry-input.scss'>
</style>