<template>
  <main class="puzzleEntry_container">
    <img
      src="@/assets/images/SnoopyScope_logo.png"
      alt=""
      class="snoopyScope_logo"
    />

    <EntryInput />

    <Footer />
  </main>
</template>

<script>
import EntryInput from "@/components/EntryInput.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "PuzzleEntry",
  components: { EntryInput, Footer },
  methods: {
    homeScreenViews() {
      this.$gtag.pageview({ page_path: "/" });
      this.$gtag.pageview("/");
      this.$gtag.pageview(this.$route);
    },
  },
  mounted() {
    this.homeScreenViews();
  },
};
</script>

<style lang="scss" src='../assets/scss/puzzle-entry.scss'></style>