<template>
  <main class="puzzlePrize_container">
    <img
      src="@/assets/images/SnoopyScope_logo.png"
      alt=""
      class="snoopyScope_logo"
    />

    <section class="comicStrip_container">
      <ComicStrip />

      <ComicSlide />

      <img src="@/assets/images/prize-text.png" alt="" class="prize_text" />
    </section>

    <router-link to="/" class="backHome_btn">RESTART</router-link>

    <Footer />
  </main>
</template>

<script>
import ComicStrip from "../components/ComicStrip.vue";
import ComicSlide from "../components/ComicSlide.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "PuzzlePrize",
  components: { ComicStrip, ComicSlide, Footer },
  methods: {
    prizePageViews() {
      this.$gtag.pageview({ page_path: "/prize" });
      this.$gtag.pageview("/prize");
      this.$gtag.pageview(this.$route);
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    window.scrollTo(0, 0);

    // Google Analytics
    this.prizePageViews();

    setTimeout(() => {
      this.$router.push({ path: "/" });
    }, 30000);
  },
};
</script>

<style lang='scss' src='../assets/scss/puzzle-prize.scss'></style>