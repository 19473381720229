<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Snoopy Scope",
      titleTemplate: "%s | A Peanuts Adventure",
      icon: "/favicon.ico",
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { vmid: "charset", charset: "utf-8" },
        {
          vmid: "viewport",
          name: "viewport",
          content: "width=device-width, initial-scale=1, minimum-scale=1",
        },
      ],
      link: [
        {
          rel: "shortcut icon",
          type: "image/png",
          href: "/favicon.ico",
        },
      ],
    };
  },
};
</script>

<style>
@import "./main.scss";
@import "./assets/scss/keyframes.scss";
</style>
